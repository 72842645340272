import React, { useState } from "react"
import { useIsJsEnabled } from 'gatsby-plugin-js-fallback'

import AnimateHeight from "react-animate-height"
import ChevronDown from "../Symbols/chevron-down"
import SymbolX from "../Symbols/symbol-x"

const ClientList = props => {
  const isJsEnabled = useIsJsEnabled();
  const [height, toggleHeight] = useState(isJsEnabled ? 0 : `auto`)

  const toggleActive = () => {
    toggleHeight(height === 0 ? `auto` : 0)
  }

  return (
    <div className="clientlist">
      <h3 className="clientlist__title" onClick={() => toggleActive()}>
        {height ? <SymbolX /> : <ChevronDown />} <a>Full Client List</a>
      </h3>
      <AnimateHeight duration={500} height={height}>
        <div
          className="clientlist__items"
          dangerouslySetInnerHTML={{ __html: props.clientList.clientList }}
        />
      </AnimateHeight>
    </div>
  )
}

export default ClientList
