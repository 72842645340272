import React from "react"
import Img from "gatsby-image"
import Reveal from "../Waypoints"

const Instafeed = ({ data }) => {
  return (
    <>
      {data?.gallery?.map(({ galleryImage, galleryImageLink }, i) => {
        if (i < 5) {
          return (
            <div key={i} className="image-group__image">
              <Reveal delay={i / 4 + 0.25}>
                <a href={galleryImageLink} target="_blank" rel="noreferrer">
                  <Img
                    fluid={galleryImage.localFile?.childImageSharp?.fluid}
                    alt={
                      galleryImage.altText
                        ? galleryImage.altText
                        : `Instagram item`
                    }
                    fadeIn={false}
                  />
                </a>
              </Reveal>
            </div>
          )
        }
      })}
    </>
  )
}

export default Instafeed
