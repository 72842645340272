import React, { useState, useEffect } from "react"
import { Waypoint } from "react-waypoint"
import { useIsJsEnabled } from 'gatsby-plugin-js-fallback'

import Title from "../Title"

const Clients = props => {
  const [revealed, setRevealed] = useState(false)
  const isJsEnabled = useIsJsEnabled();

  useEffect(() => {
    setRevealed(false)
    return (
      setRevealed(false)
    )
  }, [])

  const handleReveal = () => {
    setRevealed(true)
  }

  const setOpacity = revealed ? 1 : 0
  const setTransform = revealed ? `translateY(0%)` : `translateY(20%)`
  const setRevealedClass = revealed ? `revealed` : ``

  return (
    <Waypoint
      onEnter={handleReveal}
      scrollableAncestor={typeof window !== "undefined" ? window : null}
    >    
      <div className="container-fluid">
        <div className="row">
          <div className="col-bp1-12" style={{
                opacity: isJsEnabled ? setOpacity : 1,
                transform: isJsEnabled ? setTransform : `translateY(0%)`,
                transition: `opacity .5s ease-in-out 0.5s, transform 1s ease-in-out`,
              }}>
            <Title>Our Clients</Title>
          </div>
        </div>
        <div className="row">
          <div className="col-bp1-12">
            <ul className={`clients ${isJsEnabled ? setRevealedClass : `revealed`}`}>
              {props.clients.clients.map((client, i) => {
                return (
                  <li key={i} className="client">
                    <div
                      className="client__image"
                      dangerouslySetInnerHTML={{
                        __html: client.clientImage,
                      }}
                    ></div>
                  </li>
                )
              })}
            </ul>
            {props.clientList}
          </div>
        </div>
      </div>
    </Waypoint>
  )
}


export default Clients