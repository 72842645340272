import React, { useEffect } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"

import { toggleBackground } from "../state/background"
import { setBreadcrumbs } from "../state/breadcrumbs"
import { toggleStickyHeaderColour } from "../state/header"

import SiteLayout from "../components/SiteLayout"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import SubNav from "../components/SubNav"
import Chevron from "../components/Symbols/chevron"
import Title from "../components/Title"
import HeroAnimation from "../components/Hero/HeroAnimation"
import animAbout from "../components/Hero/animations/about.json"
import imgStatic from "../components/Hero/images/About-Us-Mob-Screen-1125px-021-01.svg"
import ImageGroup from "../components/ImageGroup"
import Instafeed from "../components/Instafeed"
import Clients from "../components/Clients"
import ClientList from "../components/Clients/ClientList"
import Services from "../components/Services"
import HeroImageStatic from "../components/Hero/HeroImageStatic"
import Reveal from "../components/Waypoints"

const About = props => {
  const {
    location,
    dispatch,
    transitionStatus,
    data: { allWpPage },
  } = props

  const {
    title,
    slug,
    seo,
    background,
    hero,
    introduction,
    aboutContent,
    gallery,
    imageGroup,
    teamMembers,
    clients,
    clientList,
  } = allWpPage.edges[0].node

  useEffect(() => {
    // change bg and sticky header colour instantly,
    dispatch(toggleBackground(background.backgroundColour))
    setTimeout(() => {
      dispatch(toggleStickyHeaderColour(background.backgroundColour))
    }, 1000)
  }, [])

  useEffect(() => {
    // update breadcrumbs state with a delay
    // so it doesn't show on old page
    setTimeout(() => {
      dispatch(
        setBreadcrumbs({
          current: {
            title: `About`,
            path: slug ? slug : `about-broadgate-creative-aylesbury`,
          },
        })
      )
    }, 1000)
  }, [])

  return (
    <SiteLayout location={location} transitionStatus={transitionStatus}>
      <Seo
        seoTitle={seo.title ? seo.title : title}
        seoDesc={seo.metaDesc ? seo.metaDesc : null}
        ogTitle={seo.opengraphTitle ? seo.opengraphTitle : seo.title}
        ogDesc={
          seo.opengraphDescription ? seo.opengraphDescription : seo.metaDesc
        }
        ogImage={
          seo.opengraphImage && seo.opengraphImage.localFile
            ? seo.opengraphImage.localFile.url
            : null
        }
      />

      <Hero
        slogan={hero.heroSlogan}
        heroImage={<HeroAnimation animationData={animAbout} />}
        heroImageStatic={<HeroImageStatic img={imgStatic} />}
        scrollMarker={true}
        transitionStatus={transitionStatus}
      />

      <main id="main" className="main-content">
        <section id="intro" className="section">
          <div className="container-fluid">
            <div className="row middle-bp3">
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay="0.5">
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{
                      __html: introduction.introduction,
                    }}
                  />
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay="0.75">
                  <SubNav title="Navigation">
                    <div className="card__col">
                      <nav className="subnav">
                        <ul>
                          <li>
                            <a
                              onClick={e => {
                                e.preventDefault()
                                scrollTo("#philosophy")
                              }}
                            >
                              Our approach <Chevron />
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={e => {
                                e.preventDefault()
                                scrollTo("#key-people")
                              }}
                            >
                              Key People <Chevron />
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={e => {
                                e.preventDefault()
                                scrollTo("#clients")
                              }}
                            >
                              Client List <Chevron />
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </SubNav>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section--image-group section--image-group--top"
          style={{ position: `relative` }}
        >
          <div className="row">
            <div className="col-bp1-12">
              <ImageGroup>
                <Instafeed data={gallery} />
              </ImageGroup>
            </div>
          </div>
        </section>

        <section className="link-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                <Reveal delay="0.5">
                  <div className="link-container">
                    <a
                      className="link link--instafeed"
                      href="https://instagram.com/broadgatecreative"
                    >
                      <span>Instagram</span>
                      <Chevron />
                    </a>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <section
          id="philosophy"
          className="section section--black"
          style={{ position: `relative` }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                <Reveal delay="0.5">
                  <Title>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: aboutContent.aboutContentTitle,
                      }}
                    ></span>
                  </Title>
                </Reveal>
              </div>
            </div>
            <div className="row">
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay="0.5">
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{
                      __html: aboutContent.aboutContentColumn1,
                    }}
                  />
                </Reveal>
              </div>
              <div className="col-bp1-12 col-bp3-6">
                <Reveal delay="0.75">
                  <div
                    className="entry"
                    dangerouslySetInnerHTML={{
                      __html: aboutContent.aboutContentColumn2,
                    }}
                  />
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section--image-group"
          style={{ position: `relative` }}
        >
          <div className="row">
            <div className="col-bp1-12">
              <ImageGroup>
                {imageGroup.imageGroup.map((image, i) => {
                  return (
                    <div key={i} className={`image-group__image`}>
                      <Reveal delay={i / 4 + 0.25}>
                        <Img
                          fluid={image.localFile.childImageSharp.fluid}
                          alt={image.localFile.altText}
                          fadeIn={false}
                        />
                      </Reveal>
                    </div>
                  )
                })}
              </ImageGroup>
            </div>
          </div>
        </section>

        <section id="key-people" className="section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-bp1-12">
                <Reveal delay="0.5">
                  <Title>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: teamMembers.teamMembersIntroductionTitle,
                      }}
                    ></span>
                  </Title>
                </Reveal>
              </div>
            </div>
            <div className="row">
              <div className="col-bp1-12 col-bp4-4">
                <div className="entry entry--team">
                  <div className="row">
                    <div className="col-bp1-12 col-bp2-6 col-bp4-12">
                      <Reveal delay="0.25">
                        <div
                          className="entry"
                          dangerouslySetInnerHTML={{
                            __html: teamMembers.teamMembersIntroductionColumn1,
                          }}
                        />
                      </Reveal>
                    </div>
                    <div className="col-bp1-12 col-bp2-6 col-bp4-12">
                      <Reveal delay="0.5">
                        <div
                          className="entry"
                          dangerouslySetInnerHTML={{
                            __html: teamMembers.teamMembersIntroductionColumn2,
                          }}
                        />
                      </Reveal>
                    </div>
                  </div>
                </div>
              </div>
              {teamMembers.teamMembers
                .filter((member, i) => {
                  return i < 2
                })
                .map((member, i) => {
                  return (
                    <div key={i} className="col-bp1-12 col-bp2-6 col-bp4-4">
                      <Reveal delay={i / 4 + 0.5}>
                        <div
                          className={`card card--space-top card--light-mode ${
                            i === 0 ? `card--blue` : `card--green`
                          }`}
                          style={{
                            overflow: `visible`,
                          }}
                        >
                          <div
                            className="card__body card__body--bg"
                            style={{
                              backgroundImage: `url(${member.teamMemberBgImage.sourceUrl})`,
                              borderRadius: `0.45888em 0.45888em 0 0`,
                            }}
                          >
                            <div className="card__col">
                              <Img
                                className="team__image"
                                fluid={
                                  member.teamMemberImage.localFile
                                    .childImageSharp.fluid
                                }
                                alt={member.teamMemberImage.altText}
                              />
                              <div className="team">
                                <h3 className="team__name">
                                  {member.teamMemberName}
                                </h3>
                                <h4 className="team__role">
                                  {member.teamMemberRole}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <footer
                            className="card__footer"
                            style={{
                              borderRadius: `0 0 0.3em 0.3em`,
                            }}
                          >
                            <a
                              href={member.teamMemberUrl}
                              data-cursor="alt"
                              target="_blank"
                            >
                              LinkedIn <Chevron />
                            </a>
                          </footer>
                        </div>
                      </Reveal>
                    </div>
                  )
                })}
            </div>
            <div className="row">
              <div className="col-bp1-12">
                <Reveal delay={0.25}>
                  <h3
                    className="title title--sub"
                    dangerouslySetInnerHTML={{
                      __html: teamMembers.teamMembersIntroductionSubtitle,
                    }}
                  />
                </Reveal>
              </div>
            </div>
            <div className="associates">
              {teamMembers.teamMembers
                .filter((member, i) => {
                  return i > 1
                })
                .map((member, i) => {
                  return (
                    <div key={i} className="associate">
                      <Reveal delay={i / 4 + 0.25}>
                        <Img
                          className="associate__image"
                          fluid={
                            member.teamMemberImage.localFile.childImageSharp
                              .fluid
                          }
                          alt={member.teamMemberImage.altText}
                        />
                        <div className="associate__body">
                          <h3 className="associate__name">
                            {member.teamMemberName}
                          </h3>
                          <p className="associate__role">
                            {member.teamMemberRole}
                          </p>
                        </div>
                      </Reveal>
                    </div>
                  )
                })}
            </div>
          </div>
        </section>

        <section
          id="clients"
          className="section section--black section--no-space-bottom"
        >
          <Clients
            clients={clients}
            clientList={<ClientList clientList={clientList} />}
          />
        </section>

        <Services />
      </main>
    </SiteLayout>
  )
}

export default connect(
  state => ({
    background: state.background.background,
    breadcrumbs: state.breadcrumbs.breadcrumbs,
  }),
  null
)(About)

export const pageQuery = graphql`
  query GET_ABOUT_PAGE($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              localFile {
                url
              }
            }
          }
          introduction {
            introduction
          }
          background {
            backgroundColour
          }
          hero {
            heroSlogan
          }
          aboutContent {
            aboutContentTitle
            aboutContentColumn1
            aboutContentColumn2
          }
          gallery {
            gallery {
              galleryImage {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              galleryImageLink
            }
          }
          imageGroup {
            imageGroup {
              sourceUrl
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          teamMembers {
            teamMembersIntroductionTitle
            teamMembersIntroductionSubtitle
            teamMembersIntroductionColumn1
            teamMembersIntroductionColumn2
            teamMembers {
              teamMemberName
              teamMemberRole
              teamMemberUrl
              teamMemberImage {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              teamMemberBgImage {
                sourceUrl
                altText
              }
            }
          }
          clients {
            clients {
              clientName
              clientImage
            }
          }
          clientList {
            clientList
          }
        }
      }
    }
  }
`
